define("discourse/plugins/discourse-pfaffmanager/discourse/models/server", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Server = _object.default.extend();
  Server.reopenClass({
    server_status(model) {
      return JSON.parse(model);
    },
    dropletCreate(model) {
      // eslint-disable-next-line no-console
      // console.log("dropletCreate in the model j/d/models/server.js.es6");
      // eslint-disable-next-line no-console
      // console.log(model);
      return (0, _ajax.ajax)(`/pfaffmanager/install/${model.id}`, {
        type: "PUT"
      }).catch(_ajaxError.popupAjaxError);
    },
    updateServerStatus(model) {
      return (0, _ajax.ajax)(`/pfaffmanager/update_server_status/${model.id}`, {
        type: "PUT"
      }).catch(_ajaxError.popupAjaxError);
    },
    setOriginalHostname() {
      this.set("originalHostname", this.hostname);
    },
    upgradeServer(model) {
      // eslint-disable-next-line no-console
      // console.log("upgrade in j/d/models/");
      // eslint-disable-next-line no-console
      // console.log(model);
      return (0, _ajax.ajax)(`/pfaffmanager/upgrade/${model.id}.json`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    userDeclaredFailure(model) {
      // eslint-disable-next-line no-console
      // console.log("user declared failure in j/d/models/server.js.es6");
      let server = {};
      server = {
        request_status: "user declared failure (clicked button)"
      };
      return (0, _ajax.ajax)(`/pfaffmanager/servers/${model.id}`, {
        type: "PUT",
        data: {
          server
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    updateServer(model) {
      let server = {};
      server = {
        user_id: model.user_id,
        username: model.username,
        hostname: model.hostname,
        discourse_url: model.discourse_url,
        do_api_key: model.do_api_key,
        mg_api_key: model.mg_api_key,
        maxmind_license_key: model.maxmind_license_key,
        droplet_size: model.droplet_size,
        droplet_region: model.droplet_region,
        install_type: model.install_type,
        ansible_user: model.ansible_user,
        smtp_host: model.smtp_host,
        smtp_password: model.smtp_password,
        smtp_user: model.smtp_user,
        smtp_port: model.smtp_port,
        smtp_notification_email: model.smtp_notification_email,
        delete_plugins: model.delete_plugins,
        custom_plugins: model.custom_plugins,
        discourse_env: model.discourse_env,
        extra_vars: model.extra_vars,
        group_id: model.group_id
      };
      return (0, _ajax.ajax)(`/pfaffmanager/servers/${model.id}`, {
        type: "PUT",
        data: {
          server
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    edit(id) {
      const servers = (0, _ajax.ajax)(`/pfaffmanager/servers/${id}`, {
        type: "GET"
      }).then(result => {
        return result.server;
      }).catch(_ajaxError.popupAjaxError);
      return servers;
    },
    async delete(id) {
      await (0, _ajax.ajax)(`/pfaffmanager/servers/${id}`, {
        type: "DELETE"
      }).then(() => {
        this.listServers();
      }).catch(_ajaxError.popupAjaxError);
      this.listServers();
    },
    listServers() {
      return (0, _ajax.ajax)(`/pfaffmanager/servers`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
    },
    listGroupServers(groupName) {
      const servers = (0, _ajax.ajax)(`/pfaffmanager/group/${groupName}`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
      return {
        servers,
        groupName
      };
    },
    listFindServers(s) {
      const servers = (0, _ajax.ajax)(`/pfaffmanager/find/${s}`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
      return {
        servers
      };
    },
    createServer(server) {
      return (0, _ajax.ajax)(`/pfaffmanager/servers`, {
        type: "POST",
        data: {
          server
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    findServer(serverId) {
      return (0, _ajax.ajax)(`/pfaffmanager/servers/${serverId}`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Server;
});