define("discourse/plugins/discourse-pfaffmanager/discourse/controllers/pfaffmanager-servers-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/discourse-pfaffmanager/discourse/models/server"], function (_exports, _controller, _object, _service, _getUrl, _decorators, _server) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Group from "discourse/models/group";
  // import User from "discourse/models/user";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    canAddDemoServer() {
      // const g = Group.findAll().then((groups) => {
      //   const x =
      //     groups.filterBy(
      //       "name",
      //       this.siteSettings.pfaffmanager_demo_server_group
      //     ).length > 0;
      //   window.console.log("x:", x);
      //   return x;
      // });
      // window.console.log("G:", g);
      return true;
    },
    noservers(servers) {
      return servers.length === 0;
    },
    canAddFreeInstall() {
      const enabled = this.siteSettings.pfaffmanager_enable_free_install;
      return enabled;
    },
    canAddExistingServer() {
      const enabled = this.siteSettings.pfaffmanager_enable_free_existing;
      return enabled;
    },
    serverNeedsConfiguration(servers) {
      let configured = true;
      servers.every(server => {
        configured = configured && !!server.server_status_json;
        return configured;
      });
      return !configured;
    },
    sessionChanged() {
      this.refresh();
    },
    dropletCreate() {
      _server.default.createServerForUser(this.model).then(result => {
        if (result.errors) {
          // eslint-disable-next-line no-console
          // console.log("Errors: ", result.errors);
        } else {
          // eslint-disable-next-line no-console
          // console.log("Success");
        }
      });
    },
    toggleServerDescriptions() {
      const current = this.get("showServerDescriptions");
      this.set("showServerDescriptions", !current);
    },
    createStdServer() {
      window.console.log("createStdServer!!!");
      const server = {
        user_id: this.currentUser.id,
        install_type: "std"
      };
      _server.default.createServer(server).then(result => {
        if (result.server) {
          this.get("servers").pushObject(_object.EmberObject.create(result.server));
          const id = result.server.id;
          window.location = (0, _getUrl.default)(`/pfaffmanager/servers/${id}`);
          this.router.transitionTo("pfaffmanager.servers.show", result.server.id);
        }
      });
    },
    createServer(installType) {
      const server = {
        user_id: this.currentUser.id,
        install_type: installType || "std"
      };
      _server.default.createServer(server).then(result => {
        if (result.server) {
          // this.get("servers").pushObject(EmberObject.create(result.server));
          const id = result.server.id;
          this.router.transitionTo("pfaffmanager.servers.show", id);
        }
      });
    }
  }, [["method", "canAddDemoServer", [(0, _decorators.default)()]], ["method", "noservers", [(0, _decorators.default)("servers")]], ["method", "canAddFreeInstall", [(0, _decorators.default)()]], ["method", "canAddExistingServer", [(0, _decorators.default)()]], ["method", "serverNeedsConfiguration", [(0, _decorators.default)("servers")]], ["method", "sessionChanged", [_object.action]], ["method", "dropletCreate", [_object.action]], ["method", "toggleServerDescriptions", [_object.action]], ["method", "createStdServer", [_object.action]], ["method", "createServer", [_object.action]]]));
});