define("discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/user-profile-primary/pfaffmanager-servers-profile", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.servers}}
    <div class="pfaffmanager-servers">
      Pfaffmanager servers
      <ul>
        {{#each model.servers as |server|}}
          <li>
            <a href="/pfaffmanager/servers/{{server.id}}">{{server.hostname}}</a>
            at
            <a href={{server.discourse_url}}>{{server.discourse_url}}</a>
          </li>
        {{/each}}
      </ul>
    </div>
  {{/if}}
  */
  {
    "id": "rJD+XRaG",
    "block": "[[[41,[30,0,[\"model\",\"servers\"]],[[[1,\"  \"],[10,0],[14,0,\"pfaffmanager-servers\"],[12],[1,\"\\n    Pfaffmanager servers\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"servers\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,3],[15,6,[29,[\"/pfaffmanager/servers/\",[30,1,[\"id\"]]]]],[12],[1,[30,1,[\"hostname\"]]],[13],[1,\"\\n          at\\n          \"],[10,3],[15,6,[30,1,[\"discourse_url\"]]],[12],[1,[30,1,[\"discourse_url\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/user-profile-primary/pfaffmanager-servers-profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/user-profile-primary/pfaffmanager-servers-profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"server\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-pfaffmanager/discourse/templates/connectors/user-profile-primary/pfaffmanager-servers-profile.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});